
import app from '../../App';

export default class TaxRatesService {
    constructor ( $log, $http,Info) {

        'ngInject';
        this._$http=$http;
        this.Info=Info;
    }

    getTaxRates(){
        return  this._$http({
            method: 'GET',
            url: `${this.Info.host}v1/autobilling/taxrates`
        });
    }
};

app.service( 'TaxRatesService', TaxRatesService );