import template from '../../../templates/checkout-flow/calculator.directive.tpl.html';
import app from '../../App';

export function calculatorDirective($cookies, RouteHelper, PricingHelper, PlansService, ErrorService) {
    'ngInject';

    return {
        restrict: 'E',
        scope: {
            pricingParams: '=',
            plancode: '=',
            promocodes: '=',
            afmc: '='
        },
        templateUrl: template,
        link: (scope) => {
            let plans;
            let currentPlan;

            scope.askQuestion = {};
            scope.saveQuote = {};
            scope.enterprisePricing = {};
            scope.questionSended = false;
            scope.isAnnualPlanSelected = scope.pricingParams.plan == 'annual' ? true : false;
            scope.isMonthlyPlanSelected = scope.pricingParams.plan == 'monthly' ? true : false;
            scope.percentageOffLicenseForAnnual = 0;
            scope.percentageOffDevicesForAnnual = 0;
            scope.saveQuoteSended = false;
            scope.anyQuestionSended = false;
            scope.isInitialized = scope.pricingParams.initialized === 'true' ? true : false;
            scope.isCheckout = scope.pricingParams.checkout === 'true' ? true : false;
            scope.enterprise = scope.pricingParams.enterprise === 'true' ? true : false;

            scope.saveQuoteHbspForm = hbspt.forms.create({
              portalId: "433851",
              formId: "3ff321c2-df2b-4c15-8c16-21c9789988d0",
              target: '#save-quote-modal-form-hbsp',
              onFormReady: onHubSpotFormReady,
              onFormSubmit: onHubSpotFormSubmit,
            });

            scope.enterpriseHbspForm = hbspt.forms.create({
              portalId: "433851",
              formId: "24bb7ad2-0248-44d0-a499-52bb56da52f8",
              target: '#enterprise-form-hbsp',
              onFormReady: onHubSpotFormReady,
              onFormSubmit: onHubSpotFormSubmit,
            });

            function onHubSpotFormReady() {
              setHubSpotValue('calculator_licenses', scope.pricingParams.displays.toString());
              setHubSpotValue('calculator_devices', scope.devices.toString());
              setHubSpotValue('calculator_annual_or_monthly_selected', scope.pricingParams.plan.toString());
              setHubSpotValue('calculator_plan_name', scope.enterprise ? 'enterprise' : 'growth');
            }

            function onHubSpotFormSubmit($form) {
              var value = JSON.parse($form.find('[name="hs_context"]')[0].value);
              value.pageUrl = scope.checkoutUrl();
              value.referrer = scope.checkoutUrl();
              $form.find('[name="hs_context"]')[0].value = JSON.stringify(value);
            }

            function handleGetStarted() {
              scope.$apply(function() {
                RouteHelper.updateRouteParams({ initialized: 'true', enterprise: 'true' });
                scope.isInitialized = true;
                scope.enterprise = true;
                window.scrollTo(0, 0)
              });
            }

            function handleContactSales() {
              scope.$apply(function() {
                scope.setDisplayCount(100);
                RouteHelper.updateRouteParams({ displays: 100, initialized: 'true', enterprise: 'true' });
                scope.isInitialized = true;
                scope.enterprise = true;
                window.scrollTo(0, 0)
              });
            }

            function setHubSpotValue(fieldName, value) {
              $('input[name="' + fieldName + '"]').val(value).change();
            }

            angular.element('#get-started-button').on('click', handleGetStarted);
            angular.element('#get-started-link').on('click', handleGetStarted);
            angular.element('#get-started-button-2').on('click', handleGetStarted);

            angular.element('#contact-sales-button').on('click', handleContactSales);
            angular.element('#request-pricing-button-2').on('click', handleContactSales);

            scope.$watch('isInitialized', function(value) {
              if (value) {
                angular.element('.section-31').hide();
                angular.element('#pricing-trusted').hide();
                angular.element('#pricing-comparsion').hide();
              } else {
                angular.element('.section-31').show();
                angular.element('#pricing-trusted').show();
                angular.element('#pricing-comparsion').show();
              }
            })

            let formatMoney = (n, c, d, t) => {
                var c = isNaN(c = Math.abs(c)) ? 2 : c,
                    d = d == undefined ? "." : d,
                    t = t == undefined ? "," : t,
                    s = n < 0 ? "-" : "",
                    i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
                    j = (j = i.length) > 3 ? j % 3 : 0;
                return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
            };

            scope.renderToCurrency = (value) => formatMoney(value, 2, '.', ',');
            scope.isEnterpriseOffer = () => scope.enterprise || scope.pricingParams.displays >= 100;

            let populatePlans = () => {
                if (scope.plancode == null) {
                    PlansService.getNewBillingPlans().then((response)=> {
                        plans = response.data.Result;
                        getPlan();
                        scope.calculatePlans();
                    });
                } else {
                    PlansService.getResellerPlan(scope.plancode).then((response)=> {
                        console.log('response');
                        if (response.data.Success) {
                            plans = [response.data.Result];
                            getPlan();
                            scope.calculatePlans();
                        } else {
                            scope.setDevicesCount(1);
                            scope.plancode = null;

                            PlansService.getNewBillingPlans().then((response)=> {
                                plans = response.data.Result;
                                getPlan();
                                scope.calculatePlans();
                            },
                            (reject) => {
                                ErrorService.sendError('',JSON.stringify(reject));
                            });
                        }
                    });
                }
            };

            let getPlan = (displaysQuantity = 0)=> {
                if (plans) {
                    return PlansService.generatePlanServiceValueObject(plans
                        .sort((a, b) => a.MaxDevices - b.MaxDevices)
                        .filter((value) => value.MaxDevices >= displaysQuantity).shift());
                }
            };

            let initSlider = () => {
                let draggable = document.getElementsByClassName('toggle'),
                    draggableCount = draggable.length,
                    i;
                let toggle;
                let startDrag = (evt) => {
                    evt.changedTouches ? evt.clientX = evt.changedTouches[0].clientX : evt;
                    evt.preventDefault();
                    toggle = evt.target;

                    const toggleWidth = toggle.offsetWidth;
                    let track = toggle.previousElementSibling,
                        diffX = evt.clientX - toggle.offsetLeft;
                    let moveAlong = (evt) => {
                        evt.changedTouches ? evt.clientX = evt.changedTouches[0].clientX : evt;
                        var left = parseInt(evt.clientX - diffX);
                        if (left < -toggleWidth/2) {
                            left = -toggleWidth/2;
                        }
                        if (left > track.offsetWidth - toggleWidth + 4) {
                            left = track.offsetWidth - toggleWidth + 4
                        }
                        toggle.style.left = left + 'px';

                        let ticks = document.getElementsByClassName("tick-text");
                        let ticksValuesOffsets = Array.prototype.map.call(ticks, (element)=> {
                            return {
                                value: parseInt(element.getAttribute('data-display-count')),
                                offset: element.offsetLeft + element.offsetWidth * 0.5
                            };
                        });

                        ticksValuesOffsets.forEach((item, index, arr)=> {
                            let toggleOffset = toggle.offsetLeft + toggle.offsetWidth * 0.5;
                            if (toggleOffset < item.offset && index >= 1 && toggleOffset > ticksValuesOffsets[index - 1].offset) {
                                let currentValue = ticksValuesOffsets[index - 1].value + parseInt((ticksValuesOffsets[index].value - ticksValuesOffsets[index - 1].value) * (toggleOffset - ticksValuesOffsets[index - 1].offset) / (ticksValuesOffsets[index].offset - ticksValuesOffsets[index - 1].offset));
                                currentValue = parseInt((parseInt((toggleOffset - ticksValuesOffsets[index - 1].offset) / ((ticksValuesOffsets[index].offset - ticksValuesOffsets[index - 1].offset) / ((ticksValuesOffsets[index].value - ticksValuesOffsets[index - 1].value) * 2))) + 1) / 2 + ticksValuesOffsets[index - 1].value);
                                scope.setDisplayCount(currentValue + 1);
                                scope.$apply();
                                scope.setDisplayCount(currentValue);
                                scope.$apply();
                            }
                        });
                    };

                    let stopDrag = () => {
                        document.removeEventListener('mousemove', moveAlong);
                        document.removeEventListener('mouseup', stopDrag);
                        document.body.removeEventListener('touchmove', moveAlong);
                        document.body.removeEventListener('touchend', stopDrag);
                        let ticks = document.getElementsByClassName("tick-text");
                        let ticksValuesOffsets = Array.prototype.map.call(ticks, (element)=> {
                            return {
                                value: parseInt(element.getAttribute('data-display-count')),
                                offset: element.offsetLeft + element.offsetWidth * 0.5
                            };
                        });
                        ticksValuesOffsets.forEach((item, index, arr)=> {
                            let toggleOffset = toggle.offsetLeft + toggle.offsetWidth * 0.5;
                            if (index == 0 && toggleOffset <= item.offset) {
                                scope.setDisplayCount(item.value + 1);
                                scope.$apply();
                                scope.setDisplayCount(item.value);
                                scope.$apply();
                            }
                            if (toggleOffset < item.offset && index >= 1 && toggleOffset > ticksValuesOffsets[index - 1].offset) {
                                let currentValue = ticksValuesOffsets[index - 1].value + parseInt((ticksValuesOffsets[index].value - ticksValuesOffsets[index - 1].value) * (toggleOffset - ticksValuesOffsets[index - 1].offset) / (ticksValuesOffsets[index].offset - ticksValuesOffsets[index - 1].offset));
                                currentValue = parseInt((parseInt((toggleOffset - ticksValuesOffsets[index - 1].offset) / ((ticksValuesOffsets[index].offset - ticksValuesOffsets[index - 1].offset) / ((ticksValuesOffsets[index].value - ticksValuesOffsets[index - 1].value) * 2))) + 1) / 2 + ticksValuesOffsets[index - 1].value);
                                scope.setDisplayCount(currentValue + 1);
                                scope.$apply();
                                scope.setDisplayCount(currentValue);
                                scope.$apply();
                            }
                            if (toggleOffset > item.offset && index == ticks.length - 1) {
                                scope.setDisplayCount(item.value - 1);
                                scope.$apply();
                                scope.setDisplayCount(item.value);
                                scope.$apply();
                            }
                        });
                    };

                    document.addEventListener('mouseup', stopDrag);
                    document.addEventListener('mousemove', moveAlong);
                    document.body.addEventListener('touchend', stopDrag);
                    document.body.addEventListener('touchmove', moveAlong);
                    return false;
                };

                if (draggableCount > 0) for (i = 0; i < draggableCount; i += 1) {
                    draggable[i].addEventListener('mousedown', startDrag);
                    draggable[i].addEventListener('touchstart', startDrag);
                }
            };

            let updateSliderPosition = (evt = null) => {
                let currentDisplaysValue = scope.pricingParams.displays;
                let toggle = document.getElementsByClassName('toggle')[0];
                const toggleWidth = toggle.offsetWidth;
                let ticks = document.getElementsByClassName("tick-text");
                let ticksValuesOffsets = Array.prototype.map.call(ticks, (element)=> {
                    return {value: parseInt(element.getAttribute('data-display-count')), offset: element.offsetLeft + element.offsetWidth * 0.5};
                });
                if (evt) {
                    evt.changedTouches ? evt.clientX = evt.changedTouches[0].clientX : evt;
                    evt.preventDefault();
                    toggle = evt.target;
                }
                ticksValuesOffsets.forEach((item, index, arr)=> {
                    ticks[index].setAttribute('selected', false);
                    if (currentDisplaysValue == item.value) {
                        toggle.style.left = item.offset - toggleWidth / 2 + 'px';
                        ticks[index].setAttribute('selected', true);
                    }
                    if (currentDisplaysValue < item.value && index >= 1 && currentDisplaysValue > ticksValuesOffsets[index - 1].value) {
                        toggle.style.left = ticksValuesOffsets[index - 1].offset + (ticksValuesOffsets[index].offset - ticksValuesOffsets[index - 1].offset) * ((currentDisplaysValue - ticksValuesOffsets[index - 1].value) / (ticksValuesOffsets[index].value - ticksValuesOffsets[index - 1].value)) - toggleWidth / 2 + 'px';
                    }
                });
            };

            scope.calculatePlans = () => {
                $cookies.putObject('calculator', {
                    numberOfLicenses: scope.pricingParams.displays,
                    numberOfDevices: scope.pricingParams.devices,
                    isAnnual: scope.isAnnualPlanSelected
                });

                if (plans && !scope.isEnterpriseOffer()) {
                    currentPlan = getPlan(displaysNum);
                    scope.isAnnualPlanSelected = scope.pricingParams.plan == 'annual' ? true : false;
                    scope.isMonthlyPlanSelected = scope.pricingParams.plan == 'monthly' ? true : false;

                    var displaysNum = scope.pricingParams.displays;

                    if (scope.getLicenseCount() !== 0) {
                        scope.monthlyPlanPerLicense = PricingHelper.getSoftwarePlanPricing(displaysNum, 'monthly');
                        scope.annualPlanPerLicense = PricingHelper.getSoftwarePlanPricing(displaysNum, 'annual');
                    } else {
                        scope.monthlyPlanPrice = 0;
                        scope.annualPlanPrice = 0;
                    }

                    // Total monthly payment for a given price variant
                    scope.monthlyPlanPrice = PricingHelper.getTotalLicense('monthly', displaysNum);
                    scope.annualPlanPrice = PricingHelper.getTotalLicense('annual', displaysNum);

                    scope.annualPlanSave = (scope.monthlyPlanPerLicense * displaysNum - scope.annualPlanPerLicense * displaysNum) * 12;
                    scope.annualPlanPricePerYear = scope.annualPlanPrice * 12;

                    scope.devicePrice = currentPlan.devicePrice;
                    scope.annualDeviceDiscount = currentPlan.percentageOffDevicesForAnnual * scope.devices * currentPlan.devicePrice;

                    switch (scope.pricingParams.plan) {
                        case "annual":
                            scope.softwarePlanPricing = scope.annualPlanPrice * 12;
                            scope.annualPlanDiscount = 12 * (parseInt(scope.monthlyPlanPrice * 100) / 100 - parseInt(scope.annualPlanPrice * 100) / 100);
                            scope.totalDevicePrice = scope.devicePrice * scope.devices - scope.annualDeviceDiscount;
                            break;
                        case "monthly":
                            scope.softwarePlanPricing = scope.monthlyPlanPrice * 12;
                            scope.annualPlanDiscount = 0;
                            scope.totalDevicePrice = scope.devicePrice * scope.devices;
                            break;
                    }

                    scope.pricePerDevice = scope.totalDevicePrice / scope.devices;
                    scope.totalPrice = scope.totalDevicePrice + scope.softwarePlanPricing;
                    scope.percentageOffLicenseForAnnual = parseInt(currentPlan.percentageOffLicensesForAnnual * 100);
                    scope.percentageOffDevicesForAnnual = parseInt(currentPlan.percentageOffDevicesForAnnual * 100);
                }
            };

            scope.setDisplayCount = (displays) => {
                scope.displaysInput = displays;
                scope.pricingParams.displays = displays;
            };

            scope.setDevicesCount = (devices) => {
                scope.devicesInput = devices;
                scope.pricingParams.devices = devices;
                scope.devices = devices;
            };

            scope.setPlanType = (planType) => {
                scope.pricingParams.plan = planType;
                scope.isAnnualPlanSelected = planType == 'annual' ? true : false;
                scope.isMonthlyPlanSelected = planType == 'monthly' ? true : false;
                scope.calculatePlans();
            };

            scope.$watch('displaysInput', (newValue) => {
                let displays = newValue != undefined ? parseInt(newValue) : scope.pricingParams.displays;

                if (displays > 1000) {
                    displays = 1000;
                } else if (displays < 1) {
                    displays = 1;
                }

                if (displays >= 1 && displays <= 1000) {
                    scope.pricingParams.displays = displays;
                }
            });

            scope.$watch('devicesInput', (newValue) => {
                let devices = newValue != undefined ? parseInt(newValue) : 0;

                if (devices > 1000) {
                    devices = 1000;
                } else if (devices < 0) {
                    devices = 0;
                }

                if (devices >= 0 && devices <= 1000) {
                    scope.pricingParams.devices = devices;
                    scope.devices = devices;
                }
            });

            scope.$watch('devices', function (newValue) {
              const devicesCount = newValue > 1000 ? 1000 : newValue;

              scope.devices = scope.pricingParams.devices = devicesCount;

              // scope.calculatePlans();
              updateSliderPosition();
              setHubSpotValue('calculator_devices', scope.devices.toString());
            });

            scope.$watch('pricingParams.displays', function (newValue, oldValue) {
              const displayCount = (newValue === undefined || newValue < 1) ? 1 : newValue > 1000 ? 1000 : newValue;
              scope.displays = scope.pricingParams.displays = displayCount;

              if (newValue != oldValue && scope.plancode == null) {
                  scope.setDevicesCount(displayCount);
              }

              // scope.calculatePlans();
              updateSliderPosition();
              setHubSpotValue('calculator_licenses', scope.pricingParams.displays.toString());
            });

            scope.$watch('pricingParams.plan', function() {
              setHubSpotValue('calculator_annual_or_monthly_selected', scope.pricingParams.plan.toString());
            });

            scope.$watch('enterprise', function() {
              setHubSpotValue('calculator_plan_name', scope.enterprise ? 'enterprise' : 'growth');
            });

            scope.getLicenseCount = () => {
                return +scope.pricingParams.displays;
            };

            scope.showSaveQuote = false;
            scope.showSaveQuoteModal = () => {
              scope.showSaveQuote = true;
            };
            scope.hideSaveQuoteModal = () => {
              scope.showSaveQuote = false;
              scope.saveQuoteSended = false;
            };

            scope.checkoutUrl = () => {
                let url = 'https://' + location.host + '/checkout?step=2&displays=' + scope.pricingParams.displays
                    + '&devices=' + scope.pricingParams.devices + '&plan=' + scope.pricingParams.plan + '&initialized=true';

                if (scope.promocodes && scope.promocodes.length > 0) {
                    url += '&promocodes=';
                    scope.promocodes.forEach(function (item, index, arr) {
                        if (index == 0) {
                            url += item.PromoCode;
                        } else {
                            url += ',' + item.PromoCode;
                        }
                    });
                }

                return url;
            }

            scope.showLearnMore = false;
            scope.showLearnMoreModal = () => {
                scope.showLearnMore = true;
            };
            scope.hideLearnMoreModal = () => {
                scope.showLearnMore = false;
            };

            scope.showAnyQuestions = false;
            scope.showAnyQuestionsModal = () => {
                scope.showAnyQuestions = true;
            };

            scope.goToCheckout = () => {
                var locationUrl;
                if (scope.plancode == null) {
                    locationUrl = '/checkout?step=2&displays=' + scope.pricingParams.displays
                        + '&devices=' + scope.devices + '&plan=' + scope.pricingParams.plan + '&initialized=true';
                } else {
                    locationUrl = '/checkout?step=2&displays=' + scope.pricingParams.displays
                        + '&devices=' + scope.devices + '&plan=' + scope.pricingParams.plan
                        + '&plancode=' + scope.plancode + '&afmc=' + scope.afmc + '&initialized=true';
                }

                location.href = locationUrl;
            };

            scope.updateDisplaysInput = () => {
                scope.displaysInput = scope.pricingParams.displays;
            }

            scope.updateDevicesInput = () => {
                scope.devicesInput = scope.pricingParams.devices;
            }

            initSlider();
            populatePlans();

            scope.setDisplayCount(scope.pricingParams.displays);
            scope.setDevicesCount(scope.pricingParams.devices != scope.pricingParams.displays ? parseInt(scope.pricingParams.devices) : parseInt(scope.pricingParams.displays));
        }
    };
};

app.directive('calculatorDirective', calculatorDirective);
