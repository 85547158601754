import initAppSwitcher from './components/AppSwitcher.js'

// main scss
import '../scss/app.scss'

// main dependencies
import angular from 'angular'
import 'angular-route'
import 'angular-cookies'

// get app instance
import app, { AppMarket } from './App'

// route templates
import PricingTpl from '../templates/calculator.tpl.html'
import CheckoutTpl from '../templates/checkout.tpl.html'
import NotFoundTpl from '../templates/not.found.tpl.html'

// import constants
import './constants/Info'

// import services
import './services/checkout-flow/EmailValidatorService'
import './services/checkout-flow/PlansService'
import './services/checkout-flow/PromoCodeService'
import './services/checkout-flow/PurchaseService'
import './services/checkout-flow/ShippingRatesService'
import './services/checkout-flow/SignUpPaidService'
import './services/checkout-flow/SignUpPaidResellerService'
import './services/checkout-flow/TaxRatesService'
import './services/checkout-flow/PayByCheckService'
import './services/checkout-flow/ShippingAddressService'
import './services/checkout-flow/DevicesService'
import './helpers/checkout-flow/RouteHelper'
import './helpers/checkout-flow/PricingHelper'
import './helpers/checkout-flow/CurrencyHelper'
import './services/checkout-flow/ErrorService'

// import controllers
import './controllers/NotFoundController'
import './controllers/PricingController'
import './controllers/CheckoutController'
import './controllers/AppMarketController'

// import directives
import './directives/checkout-flow/account-directive'
import './directives/checkout-flow/billing-directive'
import './directives/checkout-flow/calculator-directive'
import './directives/checkout-flow/checkout-directive'
import './directives/checkout-flow/confirmation-directive'
import './directives/checkout-flow/summary-directive'
import './directives/app-market/app-market'
import './directives/app-market/single-app'
import './directives/app-market/app-categories'
import './directives/app-market/app-category'
import './directives/app-market/app-market-select'

// import factories
import './factories/app-market/apps-list'

// import components
import './components/loader-button/loader-button.component'
import './components/any-questions-modal/any-questions-modal.component'

initAppSwitcher();

app.config(function ($locationProvider, $routeProvider, $compileProvider) {
    'ngInject';
    $locationProvider.html5Mode({enabled:true,rewriteLinks:false});
    $locationProvider.html5Mode(true);
    $routeProvider
        .when("/pricing", {
            name: 'pricing',
            templateUrl: PricingTpl,
            controller: 'PricingController'
        })
        .when("/checkout", {
            name: 'pricing',
            templateUrl: CheckoutTpl,
            controller: 'CheckoutController',
            reloadOnSearch:false
        })
        .otherwise({
            name: 'not-found',
            templateUrl: NotFoundTpl,
            controller: 'NotFoundController'
        });
});

// initial run + setup
app.run(function ($rootScope, Info) {
    'ngInject';
});
