
import template from '../../../templates/checkout-flow/confirmation.directive.tpl.html';

import app from '../../App';

export function confirmationDirective (Info) {

    'ngInject';

    return {
        restrict: 'E',
        scope: {
            data: '=',
            laststep:'=',
            taxrate: '=',
            selectedshippingrate: '=',
            plancode: '=',
            afmc: '='
        },
        templateUrl: template,
        link: ( scope, element, attrs ) => {
            scope.gotoLoginPage= () => {
                location.href = Info.isProduction?'https://login.enplug.com':'https://login.enplug.in';
            };
            scope.showAnyQuestionsModal = () => {
                scope.showAnyQuestions = true;

                hbspt.forms.create({
                  portalId: "433851",
                  formId: "df280e4a-9587-4803-b572-abd3e165d3eb",
                  target: '#any-question-modal-form-hbsp',
                });
            };
        }
    };
};

app.directive( 'confirmationDirective', confirmationDirective );
