
import app from '../../App';

export default class PromoCodeService {
    constructor ( $log, $http,Info) {

        'ngInject';
        this._$http=$http;
        this.Info=Info;
    }

    getPromoCodeDiscount(promoCode,numberOfLicenses=1){
        return  this._$http({
            method: 'GET',
            url: `${this.Info.host}v1/autobilling/promocode/validate?promocode=${promoCode}&numberoflicenses=${numberOfLicenses}`
        });
    }
};

app.service( 'PromoCodeService', PromoCodeService );