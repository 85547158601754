import { AppMarket } from '../App';

AppMarket.controller('AppMarket', ['$scope', '$filter', 'AppsList', function ($scope, $filter, AppsList) {
  $scope.currentPage = sessionStorage && sessionStorage.getItem('app-market-page') | 0;
  $scope.pageSize = 100;
  $scope.data = [];
  $scope.categories = [];
  $scope.categoryFilter = '';
  $scope.orderBy = '';
  $scope.orderOptions = [{
    value: '',
    label: 'Recommended First',
  }, {
    value: '-releaseDate',
    label: 'Newest',
  }, {
    value: 'name',
    label: 'Alphabetical'
  }];
  $scope.q = '';

  $scope.getData = function () {
    var itemsMatchingCategory = $filter('filterByCategories')($scope.data, $scope.categories);
    return $filter('filter')(itemsMatchingCategory, $scope.q)
  }

  $scope.numberOfPages = function () {
    return Math.ceil($scope.getData().length / $scope.pageSize);
  }

  $scope.getPagesArray = function () {
    const pages = [];
    for (var i = 1; i <= $scope.numberOfPages(); i++) {
      pages.push(i);
    }

    return pages;
  }

  $scope.setPage = function (page) {
    $scope.currentPage = page - 1;

    if (sessionStorage) {
      sessionStorage.setItem('app-market-page', $scope.currentPage);
    }

    scrollIntoView();
  }

  $scope.clearCategories = function () {
    $scope.categories.forEach((category) => {
      category.checked = false;
    });
  }

  $scope.clearFilters = function () {
    $scope.q = '';
    $scope.clearCategories();
  }

  $scope.data = AppsList;

  $scope.$watch('q', function (newValue, oldValue) {
    if (oldValue != newValue) {
      $scope.currentPage = 0;
    }
  }, true);

  $scope.$watch('categoryFilter', function (newValue) {
    const category = newValue.split('_').join(' ');
    $scope.categories.forEach((cat) => {
      if (cat.name === category) {
        cat.checked = true;
      } else {
        cat.checked = false;
      }
    });
  }, true);

  $scope.$watchCollection(function() {
    return angular.toJson($scope.categories);
  }, function () {
    $scope.currentPage = 0;
  });

  $scope.categories = AppsList
    .map((app) => app.categories)
    .reduce((acc, val) => acc.concat(val), []) // flatten all
    .filter((v, i, a) => a.indexOf(v) === i) // make unique
    .map((category) => ({ // convert to target model
      name: category,
      checked: false,
      count: getCategoryCount(category),
    }));

  function getCategoryCount(category) {
    var count = 0;
    (AppsList || []).forEach((app) => {
      if (app.categories.indexOf(category) > -1) {
        count++;
      }
    });

    return count;
  }

  $scope.categoryOptions = $scope.categories.map((category) => {
    return {
      value: category.name.split(' ').join('_'),
      label: category.name,
    };
  });

  $scope.isAllAppsActive = () => {
    var categoriesActive = $scope.categories.filter(category => category.checked).length;
    return categoriesActive === 0;
  };

  $scope.onAllAppsClick = () => {
    if ($scope.isAllAppsActive()) {
      return false;
    }

    $scope.clearCategories();
  }
}]);

AppMarket.filter('startFrom', function () {
  return function (input, start) {
    start = +start;
    return input.slice(start);
  }
});

AppMarket.filter('filterByCategories', function () {
  return function (items, categories) {
    var filtered = [];

    var categoriesActive = categories.filter(category => category.checked).length;
    if (categoriesActive === 0) {
      filtered = items;
    }

    (items || []).forEach(function (item) {
        var matches = categories.filter(category => category.checked).some(function (category) {
            return (item.categories.indexOf(category.name) > -1);
        });

        if (matches) {
            filtered.push(item);
        }
    });

    return filtered;
  };
});

function scrollIntoView() {
  $('#app-market-section')[0].scrollIntoView({ behavior: 'smooth' });
}
