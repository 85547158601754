import app from '../../App';

export class PurchaseService {
    /* @ngInject */

    constructor(ErrorService, Info, PricingHelper, SignUpPaidService, SignUpPaidResellerService) {
        this.ErrorService = ErrorService;
        this.Info = Info;
        this.PricingHelper = PricingHelper;
        this.SignUpPaidService = SignUpPaidService;
        this.SignUpPaidResellerService = SignUpPaidResellerService;

        this.isInProgress = false;

        recurly.configure({
            publicKey: Info.recurlyPublicKey,
            required: ['cvv']
        });
    }

    getToken(paymentMethod, data) {
        this.isInProgress = true;

        let billingData, recurlyService;

        if (paymentMethod === 'BA') {
            billingData = mapBillingDataBA(data.billingInfo, data.accountInfo, data.billingAddressInfo, data.shippingAddressInfo, data.pricingParams);
            recurlyService = recurly.bankAccount;
        } else {
            billingData = mapBillingDataCC(data.billingInfo, data.accountInfo, data.billingAddressInfo, data.shippingAddressInfo, data.pricingParams);
            recurlyService = recurly;
        }

        return new Promise((resolve, reject) => {
            recurlyService.token(billingData, (err, token) => {
                if (token) {
                    this.token = token;

                    resolve(token);
                } else {
                    this.isInProgress = false;
                    this.token = undefined;

                    reject(err);
                }
            });
        });
    }

    signUpPaidService(data, shippingRate) {
        const licensePrice = this.PricingHelper.getTotalLicense(data.pricingParams.plan, data.pricingParams.displays);
        const totalDevicesDiscount = this.PricingHelper.getTotalDevicesDiscount(data.pricingParams.plan, data.pricingParams.displays, data.pricingParams.devices);
        const devicesPrice = this.PricingHelper.getTotalDevices(data.pricingParams.displays, data.pricingParams.devices) - totalDevicesDiscount;

        const subscriptionDiscount = this.PricingHelper.calculateSubscriptionDiscount(data.validatedPromocodes, licensePrice, data.pricingParams.plan);
        const taxRate = this.PricingHelper.calculateTaxRate(data.shippingAddressInfo, data.taxRate);
        const promoCodes = data.validatedPromocodes.map(promo => promo.PromoCode);
        const shippingAmount = shippingRate.Amount;
        const shippingRateId = shippingRate.RateId;
        const devicesDiscount = this.PricingHelper.calculateDevicesDiscount(data.validatedPromocodes, devicesPrice);

        if (data.plancode != null) {
            return this.SignUpPaidResellerService
                .signup(data.plancode, data.accountInfo, data.billingAddressInfo, data.pricingParams, this.token.id, subscriptionDiscount, promoCodes)
                .then(
                    this.onSignUpResponse.bind(this, data.accountInfo),
                    this.onSignUpError.bind(this, data.accountInfo));
        } else {
            return this.SignUpPaidService
                .signup(data.accountInfo, data.shippingAddressInfo, data.billingInfo, data.billingAddressInfo, data.pricingParams, this.Info.deviceInfo,
                    this.token.id, taxRate, devicesDiscount, subscriptionDiscount, shippingAmount, promoCodes, data.addressID, shippingRateId)
                .then(
                    this.onSignUpResponse.bind(this, data.accountInfo),
                    this.onSignUpError.bind(this, data.accountInfo));
        }
    };

    /** @private */
    onSignUpResponse(accountInfo, response) {
        this.isInProgress = false;

        return new Promise((resolve, reject) => {
            if (response.data.Success) {
                resolve();
            } else {
                this.ErrorService.sendError(accountInfo.email, JSON.stringify(response.data.ErrorMessage));

                reject(response.data.ErrorMessage);
            }
        });
    }

    /** @private */
    onSignUpError(accountInfo, error) {
        this.isInProgress = false;

        this.ErrorService.sendError(accountInfo.email, JSON.stringify(error));

        return new Promise((resolve, reject) => {
            reject('Unknown error, please try again.');
        });
    }
}

app.service('PurchaseService', PurchaseService);

function mapBillingDataBA(billingInfo, accountInfo, billingAddressInfo, shippingAddressInfo, pricingParams) {
    return {
        // required attributes
        routing_number: billingInfo.routingNumber,
        account_number: billingInfo.accountNumber,
        account_number_confirmation: billingInfo.accountNumberConfirmation,
        account_type: 'checking',
        name_on_account: billingInfo.nameOnAccount,
        first_name: billingAddressInfo.firstName || accountInfo.firstName,
        last_name: billingAddressInfo.lastName || accountInfo.lastName,
        // optional attributes
        address1: (pricingParams.devices == 0 || billingAddressInfo.address != '') ? billingAddressInfo.address : shippingAddressInfo.address,
        address2: null,
        city: (pricingParams.devices == 0 || billingAddressInfo.city != '') ? billingAddressInfo.city : shippingAddressInfo.city,
        state: ((pricingParams.devices == 0 || billingAddressInfo.state != '') && (billingAddressInfo.country == "US" || billingAddressInfo.country == "CA" || shippingAddressInfo.country == "US" || shippingAddressInfo.country == "CA")) ? billingAddressInfo.state : shippingAddressInfo.state,
        postal_code: (pricingParams.devices == 0 || billingAddressInfo.postalcode != '') ? billingAddressInfo.postalcode : shippingAddressInfo.postalCode,
        country: (pricingParams.devices == 0 || billingAddressInfo.country != '') ? billingAddressInfo.country : shippingAddressInfo.country,
        phone: (pricingParams.devices == 0 || billingAddressInfo.country != '') ? (billingAddressInfo.phone ? billingAddressInfo.phone.replace(/[^0-9]/g, '') : '') : (shippingAddressInfo.phone ? shippingAddressInfo.phone.replace(/[^0-9]/g, '') : '')
    };
}

function mapBillingDataCC(billingInfo, accountInfo, billingAddressInfo, shippingAddressInfo, pricingParams) {
    return {
        number: billingInfo.number,
        month: billingInfo.month,
        year: billingInfo.year,
        first_name: billingAddressInfo.firstName || accountInfo.firstName,
        last_name: billingAddressInfo.lastName || accountInfo.lastName,
        cvv: billingInfo.cvv,
        address1: (pricingParams.devices == 0 || billingAddressInfo.address != '') ? billingAddressInfo.address : shippingAddressInfo.address,
        address2: (pricingParams.devices == 0 || billingAddressInfo.address2 != '') ? billingAddressInfo.address2 : shippingAddressInfo.address2,
        city: (pricingParams.devices == 0 || billingAddressInfo.city != '') ? billingAddressInfo.city : shippingAddressInfo.city,
        state: ((pricingParams.devices == 0 || billingAddressInfo.state != '') && (billingAddressInfo.country == "US" || billingAddressInfo.country == "CA" || shippingAddressInfo.country == "US" || shippingAddressInfo.country == "CA")) ? billingAddressInfo.state : shippingAddressInfo.state,
        postal_code: (pricingParams.devices == 0 || billingAddressInfo.postalcode != '') ? billingAddressInfo.postalcode : shippingAddressInfo.postalCode,
        country: (pricingParams.devices == 0 || billingAddressInfo.country != '') ? billingAddressInfo.country : shippingAddressInfo.country,
        phone: (pricingParams.devices == 0 || billingAddressInfo.country != '') ? (billingAddressInfo.phone ? billingAddressInfo.phone.replace(/[^0-9]/g, '') : '') : (shippingAddressInfo.phone ? shippingAddressInfo.phone.replace(/[^0-9]/g, '') : '')
    };
}
