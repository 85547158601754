import app from '../../App';

export default class PricingHelper {
    constructor($log, $http, Info, PlansService) {

        'ngInject';
        this._$http = $http;
        this.Info = Info;
        this.PlansService = PlansService;
        this.plans = [];
    }

    plansReady() {
        return this.plans.length > 0 ? true : false;
    }

    getPlans(plancode) {
        if (plancode != null) {
            this.PlansService.getResellerPlan(plancode).then((response) => {
                if (response.data.Success) {
                    this.plans = [response.data.Result];
                    console.log("Pricing plans downloaded");
                } else {
                    plancode = null;
                    this.PlansService.getNewBillingPlans().then((response) => {
                        this.plans = response.data.Result;
                        console.log("Pricing plans downloaded");
                    });
                }
            });
        } else {
            this.PlansService.getNewBillingPlans().then((response) => {

                this.plans = response.data.Result;
                console.log("Pricing plans downloaded");
            });
        }
    }

    getCurrentPlan(displays = 1) {
        if (this.plans.length > 0) {
            return this.PlansService.generatePlanServiceValueObject(this.plans
                .sort((a, b) => a.MaxDevices - b.MaxDevices)
                .filter((value) => value.MaxDevices >= displays).shift());
        }
    }

    /** Extracts single device price from a selected plan */
    getDevicePrice(displays = 1) {
        if (this.plans.length > 0) {
            return this.getCurrentPlan(displays).devicePrice;
        }
    }

    /** Gets total single license price for a given amount of displays */
    getSoftwarePlanPricing(displays, planType) {
        const currentPlan = this.getCurrentPlan(displays);

        if (currentPlan === undefined) {
            return 0;
        }

        let monthlyPlanPrice = currentPlan.basePrice + displays * currentPlan.pricePerLicense;
        let annualPlanPrice = monthlyPlanPrice * (1 - currentPlan.percentageOffLicensesForAnnual);

        monthlyPlanPrice = +displays === 0 ? 0 : Math.round(monthlyPlanPrice / displays * 100) / 100;
        annualPlanPrice = +displays === 0 ? 0 : Math.round(annualPlanPrice / displays * 100) / 100;

        return planType === 'annual' ? annualPlanPrice : monthlyPlanPrice;
    }

    /** Gets total per-month value of a plan for a given number of licenses */
    getTotalLicense(plan, displays = 1) {
        if (this.plans.length > 0) {
            switch (plan) {
                case 'monthly':
                    return displays * this.getSoftwarePlanPricing(displays, 'monthly')
                case 'annual':
                    return displays * this.getSoftwarePlanPricing(displays, 'annual');
            }
        }
    }

    /** Gets total value of a plan for a given number of licenses */
    getFinalLicensesPrice(plan, displays = 1) {
      if (this.plans.length > 0) {
          switch (plan) {
              case 'monthly':
                  return displays * this.getSoftwarePlanPricing(displays, 'monthly')
              case 'annual':
                  return 12 * displays * this.getSoftwarePlanPricing(displays, 'annual');
          }
      }
    }

    /** Gets total yearly discount value for a given amount of licenses */
    getTotalLicenseDiscount(plan, displays = 1) {
        if (this.plans.length > 0) {
            let currentPlan = this.getCurrentPlan(displays);
            switch (plan) {
                case 'monthly':
                    return 0;
                case 'annual':
                    return 12 * (currentPlan.basePrice + displays * currentPlan.pricePerLicense) * (currentPlan.percentageOffLicensesForAnnual);
            }
        }
    }

    getTotalDevices(displays = 1, devices = 0) {
        if (this.plans.length > 0) {
            return this.getDevicePrice(displays) * devices;
        }
    }

    getTotalDevicesDiscount(plan, displays = 1, devices = 0) {
        if (this.plans.length > 0) {
            let currentPlan = this.getCurrentPlan(displays);
            switch (plan) {
                case 'annual':
                    return currentPlan.percentageOffDevicesForAnnual * devices * currentPlan.devicePrice;
                case 'monthly':
                    return 0;
            }
        }
    }

    getAnnualPercentageOffLicenses(displays) {
        const currentPlan = this.getCurrentPlan(displays);

        return currentPlan && currentPlan.percentageOffLicensesForAnnual;
    }

    getAnnualPercentageOffDevices(displays) {
        const currentPlan = this.getCurrentPlan(displays);

        return currentPlan && currentPlan.percentageOffDevicesForAnnual;
    }

    calculateSubscriptionDiscount(promoCodes, licensePrice, planType) {
        let subscriptionDiscount = 0;

        promoCodes.forEach(
            (promo) => {
                if (promo.AppliesToDevices === false && promo.PromoCodeType === 'Dollars') {
                    subscriptionDiscount += promo.DollarsOff;
                }
                if (promo.AppliesToDevices === false && promo.PromoCodeType !== 'Dollars') {
                    subscriptionDiscount += licensePrice * promo.PctOff;
                }
            }
        );

        if (planType === 'annual') {
          return 12 * subscriptionDiscount;
        }

        return subscriptionDiscount;
    }

    calculateDevicesDiscount(promoCodes, devicePrice) {
        let devicesDiscount = 0;

        promoCodes.forEach(
            (promo) => {
                if (promo.AppliesToDevices === true && promo.PromoCodeType === 'Dollars') {
                    devicesDiscount += promo.DollarsOff;
                }
                if (promo.AppliesToDevices === true && promo.PromoCodeType !== 'Dollars') {
                    devicesDiscount += devicePrice * promo.PctOff;
                }
            }
        );

        return devicesDiscount;
    }

    calculateTaxRate(shippingAddressInfo, taxRate) {
        if (shippingAddressInfo.country == 'US' && shippingAddressInfo.state == 'CA') {
            return taxRate;
        } else {
            return { TaxRate: 0 };
        }
    }
};

app.service('PricingHelper', PricingHelper);
