import { AppMarket } from '../../App';
var apps = require('../../../assets/apps');

// Here we can add some of the apps that are not officially published through API
var additionalApps = [{
  "AppId":"zoom",
  "Name":"Zoom",
  "Version":1,
  "IconUrl":"https://www.enplug.com/images/external-content.duckduckgo-copy-2.svg",
  "Platform":"Web",
  "Created":"2019-11-23T19:42:26",
  "ReadMoreUrl":"https://www.enplug.com/apps/zoom",
  "Description":"Stream your Zoom meetings to screens across your office in an instant.",
  "Categories":["Team Communications"],
  "IsPaid":false
}];

var displayedApps = [
  'digitalsignage',
  'instagram',
  'news',
  'weather',
  'sign-builder',
  'company-templates',
  'countdown',
  'safety',
  'covid-19-awareness',
  'working-from-home',
  'zoom',
  'screencast',
  'webpage',
  'slack',
  'youtube',
  'calendar',
  'coronavirus-news',
  'yelp',
  'twitter',
  'zoning',
  'arts',
  'holidays',
  'motivational-quotes',
  'socialmediawall',
  'tripadvisor',
  'facebook',
  'directory2',
  'traffic-app',
  'graphics-corp',
  'ad-scheduler',
  'emergency',
  'daily-health-tips',
  'market-tracker',
  'business-tech-news',
  'news-in-pictures',
  'sports-center',
  'google-trends',
  'startup-vitamins',
  'geckoboard',
  'msteams'
];

AppMarket.factory('AppsList', function () {
  const appList = [];

  displayedApps.forEach((displayApp) => {
    const foundApp = apps.Result
      .concat(additionalApps)
      .filter((app) => app !== undefined)
      .find((app) => app.AppId === displayApp);

    if (foundApp === undefined) {
      console.warn('No app found for ', displayApp);
      return;
    }

    appList.push({
      id: foundApp.AppId,
      name: foundApp.Name,
      isPaid: foundApp.IsPaid,
      categories: foundApp.Categories,
      description: foundApp.Description,
      icon: foundApp.IconUrl,
      releaseDate: foundApp.Created,
      url: foundApp.ReadMoreUrl,
    });
  });

  return appList;
});
