import app from '../../App';

export default class PayByCheckService {
    constructor($log, $http, Info) {

        'ngInject';
        this._$http = $http;
        this.Info = Info;
    }

    getFields() {
        return this._$http({
            method: 'GET',
            url: `https://api.hubapi.com/forms/v2/fields/62499a72-00a8-49a6-846f-3f98eee0bf4d?hapikey=${this.Info.hubspotApiKey}`
        });
    }

    sendPayByCheckForm(name = '', email = '', phone = '', companyName = '', plan = 'monthly', displays = 0, devices = 0) {
        return this._$http({
            method: 'POST',
            url: `${this.Info.host}v1/autobilling/submithubspotform`,
            data: {
                FormGuid: '62499a72-00a8-49a6-846f-3f98eee0bf4d',
                Fields: [
                    {"Name": "firstname", "Value": name},
                    {"Name": "lastname", "Value": ''},
                    {"Name": "email", "Value": email},
                    {"Name": "phone", "Value": phone},
                    {"Name": "message", "Value": ''},
                    {"Name": "calculator_plan_name", "Value": ''},
                    {"Name": "calculator_annual_or_monthly_selected", "Value": ''},
                    {"Name": "calculator_licenses", "Value": ''},
                    {"Name": "calculator_devices", "Value": ''},
                    {"Name": "calculator_checkout_link", "Value": ''},
                    {"Name": "calculator_price_of_next_license", "Value": ''},
                    {"Name": "calculator_pdf_quote_link", "Value": ''}
                ]
            },
            // transformRequest: function (obj) {
            //     var str = [];
            //     for (var p in obj)
            //         if (encodeURIComponent(obj[p]) != '')
            //             str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            //     console.log(str.join("&"));
            //     return str.join("&");
            // },
            // headers: {
            //     'Content-Type': 'application/x-www-form-urlencoded'
            // }
        });
    }
};

app.service('PayByCheckService', PayByCheckService);