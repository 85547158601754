import app from '../../App';
import templateUrl from '../../../templates/checkout-flow/any-questions-modal.component.tpl.html';

export class AnyQuestionsModalComponent {
    constructor() {
        this.bindings = {
            showModal: '=',
            pricingParams: '<',
            promocodes: '<',
            hubspotId: '=',
        };
        this.templateUrl = templateUrl;
        this.controller = AnyQuestionsModalController;
    }
}

class AnyQuestionsModalController {
    /* @ngInject */

    constructor(ErrorService, PricingHelper, RouteHelper) {
        this.ErrorService = ErrorService;
        this.PricingHelper = PricingHelper;
        this.formSent = false;
        this.RouteHelper = RouteHelper;
        this.questionData = {};
    }

    getSoftwarePlanPricing() {
        const planType = this.pricingParams.plan;
        const displays = this.pricingParams.displays;
        const pricePerMonth = this.PricingHelper.getSoftwarePlanPricing(displays, planType);

        return planType === 'annual' ? parseInt(pricePerMonth * 100) / 100 * 12 : pricePerMonth;
    }

    hideModal() {
        this.showModal = false;
        this.formSent = false;
    }

    $postLink() {
      var scope = this;

      hbspt.forms.create({
        portalId: "433851",
        formId: "df280e4a-9587-4803-b572-abd3e165d3eb",
        target: '#' + (scope.hubspotId || 'any-question-modal-form-hbsp'),
        onFormReady: function(form) {
          $(form).find('input[name="calculator_licenses"]').val(scope.pricingParams.displays).change();
          $(form).find('input[name="calculator_devices"]').val(scope.pricingParams.devices).change();
          $(form).find('input[name="calculator_annual_or_monthly_selected"]').val(scope.pricingParams.plan).change();
          $(form).find('input[name="calculator_plan_name"]').val(scope.pricingParams.enterprise === 'true' ? 'enterprise' : 'growth').change();
        },
      });
    }

}

app.component('anyQuestionsModal', new AnyQuestionsModalComponent());
