
function init () {
    let appSwitcher = document.getElementsByClassName("app-switcher")[0];
    let appButtons=[];
    if(appSwitcher) {
        appButtons = appSwitcher.getElementsByClassName("app-button");
    }
    let newImage = new Image();
    let controlledByUser = false;
    let currentIndex=1;
    let rotateApp = function () {
        if(!controlledByUser && appSwitcher){
            selectApp({currentTarget:appButtons[currentIndex]});
            if(currentIndex<appButtons.length-1){
                currentIndex++;
            }else{
                currentIndex=0;
            }
        }else{
            clearInterval(rotateInterval);
        }
    };

    let selectApp = function (e) {
        if(e.bubbles){
            controlledByUser=true;
        }
        let t = e.currentTarget,
            newSrc = t.children[0].getAttribute('href'),
            newName = t.children[0].getElementsByClassName("app-name")[0].innerHTML,
            newDescription = t.children[0].getElementsByClassName("app-description")[0].innerHTML,
            currentImage = appSwitcher.getElementsByClassName('current-image')[0],
            classList = currentImage.classList,
            currentImageWrapper = appSwitcher.getElementsByClassName('current-image-wrapper')[0],
            currentAppInfo = document.getElementsByClassName('current-app-info')[0];


        currentImageWrapper.style.backgroundImage = 'url(\'' + currentImage.src + '\')';

        newImage.onload = function(){
            if (classList) {
                classList.add('hideme');
            }
            Array.from(appButtons).forEach(function (element) {
                element.setAttribute("enabled", "0");
            });

            t.setAttribute("enabled", "1");
            currentImage.onload = function(){
                if (classList) {
                    classList.remove('hideme');
                }
                currentAppInfo.getElementsByClassName("app-name")[0].innerHTML = newName;
                currentAppInfo.getElementsByClassName("app-description")[0].innerHTML = newDescription;
            };
            currentImage.src = newSrc;
        };
        newImage.src = currentImage.src;
        if(e.bubbles) {
            e.preventDefault();
        }
    };

    Array.from(appButtons).forEach(function (element) {
        element.addEventListener('click', selectApp, false);
    });


    let rotateInterval=setInterval(() => {
        rotateApp();
    }, 5000);
}

export default init;
