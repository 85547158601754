
import template from '../../../templates/checkout-flow/account.directive.tpl.html';

import app from '../../App';

export function accountDirective (ShippingAddressService,EmailValidatorService,Info,PricingHelper,ErrorService) {
    
    'ngInject';

    return {
        restrict: 'E',
        scope: {
            data: '=',
            plancode: '=',
            afmc: '=',
            accountForm: '=',
            shippingForm: '='
        },
        templateUrl: template,
        link: ( scope, element, attrs ) => {
            scope.Info=Info;
            scope.emailValid=true;
            scope.addressErrorShow=false;
            scope.addressError='';
            scope.addressValidated=false;
            scope.$watch('data.accountInfo.email',(newValue, oldValue)=>{
                EmailValidatorService.validate(encodeURIComponent(newValue)).then(
                    (response)=>{
                        if(response.data.Success){
                            scope.emailValid=true;
                        }else{
                            scope.emailValid=false;
                        }
                        if(newValue == ''){
                            scope.emailValid=true;
                        }
                    },
                    (reject)=>{
                        // console.log(reject);

                        ErrorService.sendError(scope.data.accountInfo.email,JSON.stringify(reject));
                    }
                );
            });
            scope.$watch('data.shippingAddressInfo.country', () => {
                scope.data.shippingAddressInfo.state='';
            },true);
            scope.submitAccountInfo = () => {
                if(scope.accountAddress.$valid && scope.data.pricingParams.devices>0) {
                    ShippingAddressService.validateAddress(scope.data.shippingAddressInfo, scope.data.accountInfo).then(
                        (response) => {
                            if (response.data.Success == true) {
                                scope.data.addressID = response.data.Result;
                                scope.addressErrorShow = false;
                                scope.addressValidated = true;
                                scope.data.step=3;
                            } else {
                                scope.addressError = response.data.ErrorMessage;
                                scope.addressErrorShow = true;
                                scope.addressValidated = false;
                            }
                        },
                        (reject) => {
                            ErrorService.sendError(scope.data.accountInfo.email,JSON.stringify(reject));
                        }
                    );
                }
                if(scope.accountInfo.$valid && scope.data.pricingParams.devices==0){
                    scope.data.step=3;
                }
            };
        }
    };
};

app.directive( 'accountDirective',accountDirective );
