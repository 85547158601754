
import app from '../../App';

export default class ErrorService {
    constructor ( $log, $http, Info) {

        'ngInject';
        this._$http=$http;
        this.Info=Info;
    }

    sendError(email='',message='',name='',phone='',url='',plan='monthly',displays=0,devices=0,planRate=0){
        return  this._$http({
            method: 'POST',
            url: `${this.Info.host}v1/autobilling/submithubspotform`,
            data: {
                FormGuid:'db228563-0d70-4d53-8536-3ca626d63271',
                Fields: [
                    {"Name":"firstname", "Value": (name.split(" ").length>1)?name.split(" ")[0]:name},
                    {"Name":"lastname", "Value":(name.split(" ").length>1)?name.split(" ").slice(1).join(" "):''},
                    {"Name":"email", "Value":email},
                    {"Name":"phone", "Value":phone},
                    {"Name":"message", "Value":message},
                    {"Name":"calculator_plan_name", "Value":plan},
                    {"Name":"calculator_annual_or_monthly_selected", "Value":plan},
                    {"Name":"calculator_licenses", "Value":displays},
                    {"Name":"calculator_devices", "Value":devices},
                    {"Name":"calculator_checkout_link", "Value":url},
                    {"Name":"calculator_plan_rate", "Value":planRate}
                ]
            }
        });
    }
};

app.service( 'ErrorService', ErrorService );