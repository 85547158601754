import { AppMarket } from '../../App';
import template from '../../../templates/app-market/app-category.tpl.html';

AppMarket.directive('enplugAppCategory', function (){
  return {
    restrict: 'EA',
    require: 'ngModel',
    replace: true,
    templateUrl: template,
    scope: {
      ngModel: '='
    },
    link: function(scope, element) {
      element.bind('click', function(){
        scope.ngModel.checked = !scope.ngModel.checked;
        element.find('.c-checkbox').toggleClass('checked', scope.ngModel.checked);
        scope.$apply();

        scope.$watch('ngModel.checked', function (newValue) {
          element.find('.c-checkbox').toggleClass('checked', newValue);
        });
      })
    }
  };
});
