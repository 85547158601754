import app from '../../App';

export default class PlansService {
    generatePlanServiceValueObject(obj) {
        return {
            basePrice:obj.BasePrice,
            maxDevices:obj.MaxDevices,
            minDevices:obj.MinDevices,
            name:obj.Name?obj.Name:'',
            percentageOffDevicesForAnnual:obj.PercentageOffDevicesForAnnual,
            pricePerLicense:obj.PricePerLicense,
            devicePrice:obj.DevicePrice?obj.DevicePrice:299, // DevicePrice should be defined on backend
            percentageOffLicensesForAnnual:obj.PercentageOffLicensesForAnnual
        };
    };
    constructor($log, $http, Info) {
        'ngInject';
        this._$http = $http;
        this.Info=Info;
    }

    getNewBillingPlans() {
        return this._$http({
            method: 'GET',
            url: `${this.Info.host}v1/autobilling/plans`
        });
    }

    getResellerPlan(planCode) {
        return this._$http({
            method: 'GET',
            url: `${this.Info.host}v1/autobilling/plan?plancode=${planCode}`
        });
    }
};

app.service('PlansService', PlansService);
