import { AppMarket } from '../../App';
import template from '../../../templates/app-market/app-market-select.tpl.html';

AppMarket.directive('enplugAppMarketSelect', function (){
  return {
    restrict: 'EA',
    require: 'ngModel',
    replace: true,
    templateUrl: template,
    scope: {
      value: '=ngModel',
      options: '=',
      boxed: '=?',
      label: '=?',
    },
    link: function(scope) {
      scope.getSelectedValueLabel = function() {
        var selectedOption = scope.options.find((option) => option.value === scope.value);
        return selectedOption ? selectedOption.label : 'All';
      }
    }
  };
});
