
import app from '../../App';

export default class DevicesService {
    constructor ( $log, $http,Info) {

        'ngInject';
        this._$http=$http;
        this.Info=Info;
    }

    getDevicesInfo(){
        return  this._$http({
            method: 'GET',
            url: `${this.Info.host}v1/autobilling/devices`
        });
    }
};

app.service( 'DevicesService', DevicesService );