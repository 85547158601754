
import template from '../../../templates/checkout-flow/summary.directive.tpl.html';

import app from '../../App';

export function summaryDirective(TaxRatesService, PromoCodeService, PricingHelper, CurrencyHelper, PurchaseService, ErrorService) {

    'ngInject';

    return {
        restrict: 'E',
        scope: {
            data: '=',
            laststep: '=',
            annualSaveDetails: '<',
            selectedshippingrate: '=',
            softwarePricingPlan: '<',
            plancode: '=',
            afmc: '=',
            payby: '=',
            isPurchaseDisabled: '<',
            isPurchaseLoading: '<',
            isPurchaseError: '<',
            onPurchaseClick: '&',
            isAnnualUpsellingVisible: '<'
        },
        templateUrl: template,
        link: (scope, element, attrs) => {
            scope.vsTrialActive = false;
            scope.trialQuantity = 30;
            scope.trialUnit = 'days';
            scope.CurrencyHelper = CurrencyHelper;
            scope.currentPromoCode = '';
            scope.planType = scope.data.pricingParams.plan;
            scope.currentPlan = scope.planType[0].toUpperCase() + scope.planType.substring(1);
            scope.showPromoError = false;
            scope.promoErrorMessage = '';
            scope.promoCodeThatMakeError = '';
            scope.trialOverDate = new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000).toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }).split(",")[1];

            scope.$watch('data', (newValue, oldValue) => {
                if (scope.data.pricingParams.devices == 0) {
                    scope.selectedshippingrate = { Amount: 0 };
                }
                scope.updateData();
            }, true);
            scope.$watch('currentPromoCode', (newValue) => {
                if (newValue != scope.promoCodeThatMakeError) {
                    scope.showPromoError = false;
                }
            });

            scope.updateData = () => {
                scope.planType = scope.data.pricingParams.plan;
                scope.currentPlan = scope.planType[0].toUpperCase() + scope.planType.substring(1);

                scope.licensePrice = PricingHelper.getTotalLicense(scope.data.pricingParams.plan, scope.data.pricingParams.displays);
                scope.totalLicensePrice = !scope.isAnnualPlan() ? scope.licensePrice : scope.licensePrice * 12;

                scope.annualPlanLicensesDiscount = PricingHelper.getTotalLicenseDiscount('annual', scope.data.pricingParams.displays);
                scope.annualPlanSingleDeviceDiscount = PricingHelper.getTotalDevicesDiscount('annual', scope.data.pricingParams.displays, 1);
                scope.annualPlanDevicesDiscount = PricingHelper.getTotalDevicesDiscount('annual', scope.data.pricingParams.displays, scope.data.pricingParams.devices);
                const licensesDiscount = PricingHelper.getTotalLicenseDiscount(scope.data.pricingParams.plan, scope.data.pricingParams.displays);
                const devicesDiscount = PricingHelper.getTotalDevicesDiscount(scope.data.pricingParams.plan, scope.data.pricingParams.displays, scope.data.pricingParams.devices);
                scope.annualPlanLicensesPercentageOff = PricingHelper.getAnnualPercentageOffLicenses(scope.data.pricingParams.displays);
                scope.annualPlanDevicesPercentageOff = PricingHelper.getAnnualPercentageOffDevices(scope.data.pricingParams.displays);

                scope.devicesPrice = PricingHelper.getTotalDevices(scope.data.pricingParams.displays, scope.data.pricingParams.devices) - devicesDiscount;
                scope.pricePerDevice = scope.devicesPrice / scope.data.pricingParams.devices;
                scope.pricePerLicense = scope.licensePrice / scope.data.pricingParams.displays;

                scope.totalDiscount = licensesDiscount + devicesDiscount;
                scope.totalPrice = scope.totalLicensePrice + scope.devicesPrice;

                scope.data.validatedPromocodes.forEach(
                    (promo) => {
                        const discountAmount = scope.applyDiscount(promo);
                        scope.totalDiscount += discountAmount;
                        scope.totalPrice -= discountAmount;
                        if (promo.PromoCodeType == "FreeTrial") {
                            scope.vsTrialActive = true;
                            scope.trialQuantity = promo.TemporalAmount;
                            switch (promo.TemporalUnit) {
                                case 'Day':
                                    scope.trialOverDate = new Date(new Date().getTime() + scope.trialQuantity * 24 * 60 * 60 * 1000).toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }).split(",")[1];
                                    break;
                                case 'Week':
                                    scope.trialOverDate = new Date(new Date().getTime() + 7 * scope.trialQuantity * 24 * 60 * 60 * 1000).toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }).split(",")[1];
                                    break;
                                case 'Month':
                                    scope.trialOverDate = new Date(new Date().getTime() + 30 * scope.trialQuantity * 24 * 60 * 60 * 1000).toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }).split(",")[1];
                                    break;
                                case 'Yaar':
                                    scope.trialOverDate = new Date(new Date().getTime() + 365 * scope.trialQuantity * scope.trialQuantity * 24 * 60 * 60 * 1000).toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }).split(",")[1];
                                    break;
                            }
                            if (scope.trialQuantity < 2) {
                                scope.trialUnit = promo.TemporalUnit.toLowerCase();
                            } else {
                                scope.trialUnit = promo.TemporalUnit.toLowerCase() + "s";
                            }
                        } else {
                            scope.vsTrialActive = false;
                        }
                    }
                );
                scope.tax = scope.devicesPrice * PricingHelper.calculateTaxRate(scope.data.shippingAddressInfo, scope.data.taxRate).TaxRate;

                scope.totalPriceWithTax = scope.totalPrice + scope.tax + scope.selectedshippingrate.Amount;
            };

            scope.applyDiscount = (promo) => {
                if (promo.PromoCodeType == "Dollars") {
                    return promo.DollarsOff;
                } else {
                    if (promo.AppliesToDevices) {
                        return scope.totalLicensePrice * promo.PctOff + scope.totalLicensePrice * promo.PctOff;
                    } else {
                        return scope.totalLicensePrice * promo.PctOff;
                    }
                }
            };

            scope.applyPromoCode = (value) => {
                PromoCodeService.getPromoCodeDiscount(value ? value : scope.currentPromoCode, scope.data.pricingParams.displays).then(
                    (response) => {
                        if (response.data.Success) {
                            let newArray = [];
                            newArray.push(response.data.Result);
                            scope.data.validatedPromocodes = newArray.splice(0);
                            if (response.data.Result.PromoCodeType == "FreeTrial") {
                                scope.vsTrialActive = true;
                                scope.trialQuantity = response.data.Result.TemporalAmount;
                                switch (response.data.Result.TemporalUnit) {
                                    case 'Day':
                                        scope.trialOverDate = new Date(new Date().getTime() + scope.trialQuantity * 24 * 60 * 60 * 1000).toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }).split(",")[1];
                                        break;
                                    case 'Week':
                                        scope.trialOverDate = new Date(new Date().getTime() + 7 * scope.trialQuantity * 24 * 60 * 60 * 1000).toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }).split(",")[1];
                                        break;
                                    case 'Month':
                                        scope.trialOverDate = new Date(new Date().getTime() + 30 * scope.trialQuantity * 24 * 60 * 60 * 1000).toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }).split(",")[1];
                                        break;
                                    case 'Yaar':
                                        scope.trialOverDate = new Date(new Date().getTime() + 365 * scope.trialQuantity * scope.trialQuantity * 24 * 60 * 60 * 1000).toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }).split(",")[1];
                                        break;
                                }
                                if (scope.trialQuantity < 2) {
                                    scope.trialUnit = response.data.Result.TemporalUnit.toLowerCase();
                                } else {
                                    scope.trialUnit = response.data.Result.TemporalUnit.toLowerCase() + "s";
                                }
                            } else {
                                scope.vsTrialActive = false;
                            }
                            newArray = undefined;
                            scope.currentPromoCode = '';
                        } else {
                            scope.promoCodeThatMakeError = scope.currentPromoCode.slice(0);
                            scope.showPromoError = true;
                            scope.promoErrorMessage = response.data.ErrorMessage;
                            ErrorService.sendError(scope.data.accountInfo.email, JSON.stringify(response.data.ErrorMessage));
                        }
                    },
                    (reject) => {
                        scope.currentPromoCode = '';
                        ErrorService.sendError(scope.data.accountInfo.email, JSON.stringify(reject));
                    }
                );
            };

            scope.removePromoCode = promoCode => {
                scope.data.validatedPromocodes = scope.data.validatedPromocodes.filter(code => code !== promoCode);
            };

            scope.isAnnualPlan = () => scope.planType === 'annual';
            scope.switchPlanType = event => {
                scope.data.pricingParams.plan = scope.isAnnualPlan() ? 'monthly' : 'annual';
            };

            scope.getBillingPlanPeriod = () => {
                switch (scope.planType) {
                    case 'annual':
                        return 'Annually';
                    case 'monthly':
                        return 'Monthly';
                }
            };

            scope.showAnyQuestionsModal = () => {
                scope.showAnyQuestions = true;
            };

            if (scope.data.promocodes.length > scope.data.validatedPromocodes) {
                scope.data.promocodes.forEach((item) => {
                    scope.applyPromoCode(item);
                });
            }

            scope.updateData();
        }
    };
};

app.directive('summaryDirective', summaryDirective);
