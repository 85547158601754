
import app from '../../App';

export default class ShippingRatesService {
    constructor ( $log, $http,Info) {

        'ngInject';
        this._$http=$http;
        this.Info=Info;
    }

    getShippingRates(shippingAddress,companyName=''){
        return  this._$http({
            method: 'POST',
            data: {
                FirstName: shippingAddress.firstName,
                LastName : shippingAddress.lastName,
                Address1 : shippingAddress.address,
                Address2: shippingAddress.address2,
                City : shippingAddress.city,
                ZipCode: shippingAddress.postalCode,
                State : shippingAddress.state?shippingAddress.state:"",
                Country: shippingAddress.country,
                Company: companyName
            },
            url: `${this.Info.host}v1/autobilling/shippingrates`
        });
    }
    getShippingOptions(addressId,numberOfDevices){
        return  this._$http({
            method: 'GET',
            url: `${this.Info.host}v1/autobilling/shippingrates?addressid=${addressId}&numberofdevices=${numberOfDevices}`
        });
    }
};

app.service( 'ShippingRatesService', ShippingRatesService );