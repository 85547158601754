import app from '../../App';

export default class SignUpPaidService {
    constructor($log, $http, Info,PricingHelper) {

        'ngInject';
        this._$http = $http;
        this.Info = Info;
        this.PricingHelper=PricingHelper;
    }

    signup(accountInfo,shippingAddressInfo,billingInfo,billingAddressInfo,pricingInfo,deviceInfo,token,taxRate,devicesDiscount,subscriptionDiscount,shippingCost,promoCodes,addressId,rateId) {
        if(pricingInfo.devices>0) {
            return this._$http({
                method: 'POST',
                data: {
                    Email: accountInfo.email,
                    Company: accountInfo.companyName,
                    FirstName: accountInfo.firstName,//(billingAddressInfo.firstName == '' && pricingInfo.devices > 0) ? shippingAddressInfo.firstName : billingAddressInfo.firstName,
                    LastName: accountInfo.lastName,//(billingAddressInfo.lastName == '' && pricingInfo.devices > 0) ? shippingAddressInfo.lastName : billingAddressInfo.lastName,
                    Password: accountInfo.password,
                    NumberOfLicenses: pricingInfo.displays,
                    IsAnnual: (pricingInfo.plan == 'annual') ? true : false,
                    Devices: [{
                        Sku: deviceInfo.Sku,
                        Title: deviceInfo.ProductTitle,
                        Price: deviceInfo.Price,
                        Count: pricingInfo.devices
                    }],
                    PromoCodeIds: promoCodes,
                    BillingToken: token,
                    // BillingInfo: {
                    //     Token: token,
                    //     FirstName: billingInfo.firstName,
                    //     LastName: billingInfo.lastName,
                    //     Address1: (billingAddressInfo.address == '' && pricingInfo.devices > 0) ? shippingAddressInfo.address : billingAddressInfo.address,
                    //     Address2: (billingAddressInfo.address2 == '' && pricingInfo.devices > 0) ? shippingAddressInfo.address2 : billingAddressInfo.address2,
                    //     City: (billingAddressInfo.city == '' && pricingInfo.devices > 0) ? shippingAddressInfo.city : billingAddressInfo.city,
                    //     Country: (billingAddressInfo.country == '' && pricingInfo.devices > 0) ? shippingAddressInfo.country : billingAddressInfo.country,
                    //     State: (billingAddressInfo.state == '' && pricingInfo.devices > 0) ? shippingAddressInfo.state : billingAddressInfo.state,
                    //     ZipCode: (billingAddressInfo.postalcode == '' && pricingInfo.devices > 0) ? shippingAddressInfo.postalCode : billingAddressInfo.postalcode,
                    //     Phone: ''
                    // },
                    ShippingInfo: pricingInfo.devices == 0 ? null : {
                        AddressId: addressId,
                        RateId: rateId
                    },
                    Charges: {
                        DevicesBase: Math.round((this.PricingHelper.getTotalDevices(pricingInfo.displays, pricingInfo.devices) - this.PricingHelper.getTotalDevicesDiscount(pricingInfo.plan, pricingInfo.displays, pricingInfo.devices)) * 100) / 100,
                        DevicesDiscount: Math.round(devicesDiscount * 100) / 100,
                        DevicesTax: Math.round((taxRate.TaxRate * ( this.PricingHelper.getTotalDevices(pricingInfo.displays, pricingInfo.devices) - this.PricingHelper.getTotalDevicesDiscount(pricingInfo.plan, pricingInfo.displays, pricingInfo.devices))) * 100) / 100,
                        DevicesShipping: shippingCost,
                        SubscriptionBase: Math.round(this.PricingHelper.getFinalLicensesPrice(pricingInfo.plan, pricingInfo.displays) * 100) / 100,
                        SubscriptionDiscount: Math.round(subscriptionDiscount * 100) / 100
                    }
                },
                url: `${this.Info.host}v1/autobilling/signup/paid`
            });
        }

        if(pricingInfo.devices==0) {
            return this._$http({
                method: 'POST',
                data: {
                    Email: accountInfo.email,
                    Company: accountInfo.companyName,
                    FirstName: accountInfo.firstName,//(billingAddressInfo.firstName == '' && pricingInfo.devices > 0) ? shippingAddressInfo.firstName : billingAddressInfo.firstName,
                    LastName: accountInfo.lastName,//(billingAddressInfo.lastName == '' && pricingInfo.devices > 0) ? shippingAddressInfo.lastName : billingAddressInfo.lastName,
                    Password: accountInfo.password,
                    NumberOfLicenses: pricingInfo.displays,
                    IsAnnual: (pricingInfo.plan == 'annual') ? true : false,
                    PromoCodeIds: promoCodes,
                    BillingToken: token,
                    // BillingInfo: {
                    //     Token: token,
                    //     FirstName: billingInfo.firstName,
                    //     LastName: billingInfo.lastName,
                    //     Address1: (billingAddressInfo.address == '' && pricingInfo.devices > 0) ? shippingAddressInfo.address : billingAddressInfo.address,
                    //     Address2: (billingAddressInfo.address2 == '' && pricingInfo.devices > 0) ? shippingAddressInfo.address2 : billingAddressInfo.address2,
                    //     City: (billingAddressInfo.city == '' && pricingInfo.devices > 0) ? shippingAddressInfo.city : billingAddressInfo.city,
                    //     Country: (billingAddressInfo.country == '' && pricingInfo.devices > 0) ? shippingAddressInfo.country : billingAddressInfo.country,
                    //     State: (billingAddressInfo.state == '' && pricingInfo.devices > 0) ? shippingAddressInfo.state : billingAddressInfo.state,
                    //     ZipCode: (billingAddressInfo.postalcode == '' && pricingInfo.devices > 0) ? shippingAddressInfo.postalCode : billingAddressInfo.postalcode,
                    //     Phone: ''
                    // },
                    ShippingInfo: pricingInfo.devices == 0 ? null : {
                        AddressId: addressId,
                        RateId: rateId
                    },
                    Charges: {
                        DevicesBase: Math.round((this.PricingHelper.getTotalDevices(pricingInfo.displays, pricingInfo.devices) - this.PricingHelper.getTotalDevicesDiscount(pricingInfo.plan, pricingInfo.displays, pricingInfo.devices)) * 100) / 100,
                        DevicesDiscount: Math.round(devicesDiscount * 100) / 100,
                        DevicesTax: Math.round((taxRate.TaxRate * ( this.PricingHelper.getTotalDevices(pricingInfo.displays, pricingInfo.devices) - this.PricingHelper.getTotalDevicesDiscount(pricingInfo.plan, pricingInfo.displays, pricingInfo.devices))) * 100) / 100,
                        DevicesShipping: shippingCost,
                        SubscriptionBase: Math.round(this.PricingHelper.getFinalLicensesPrice(pricingInfo.plan, pricingInfo.displays) * 100) / 100,
                        SubscriptionDiscount: Math.round(subscriptionDiscount * 100) / 100
                    }
                },
                url: `${this.Info.host}v1/autobilling/signup/paid`
            });
        }
    }
};

app.service('SignUpPaidService', SignUpPaidService);
