
import app from '../../App';

export default class ShippingAddressService {
    constructor ( $log, $http,Info) {

        'ngInject';
        this._$http=$http;
        this.Info=Info;
    }

    getStates(countryCode) {
        return  this._$http({
            method: 'GET',
            url: `${this.Info.host}v1/country/${countryCode}/states`
        });
    }

    validateAddress(shippingAddress,accountInfo){
        return this._$http({
            method: 'POST',
            data: {
                FirstName: shippingAddress.firstName,
                LastName: shippingAddress.lastName,
                Company: accountInfo.companyName,
                Address1: shippingAddress.address,
                Address2: shippingAddress.address2,
                City: shippingAddress.city,
                Country: shippingAddress.country,
                State: shippingAddress.state ? shippingAddress.state : '',
                ZipCode: shippingAddress.postalCode,
                Phone: shippingAddress.phone.replace(/[^0-9]/g, '')
            },
            url: `${this.Info.host}v1/autobilling/validateaddress`
        });
    }
};

app.service( 'ShippingAddressService', ShippingAddressService );