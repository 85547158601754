
import template from '../../../templates/checkout-flow/billing.directive.tpl.html';

import app from '../../App';

export function billingDirective(PayByCheckService, Info, PurchaseService, ShippingRatesService, CurrencyHelper, ErrorService) {

    'ngInject';

    return {
        restrict: 'E',
        scope: {
            data: '=',
            selectedshippingrate: '=',
            plancode: '=',
            afmc: '=',
            payby: '=',
            billingForm: '=?',
            ccError: '<',
            baError: '<',
            paramError: '<',
            isPurchaseDisabled: '<',
            isPurchaseLoading: '<',
            isPurchaseError: '<',
            onPurchaseClick: '&'
        },
        templateUrl: template,
        link: (scope, element, attrs) => {
            let latestRecurlyCall = 0;
            scope.Info = Info;
            scope.CurrencyHelper = CurrencyHelper;
            scope.billing = {};
            scope.billing.payBy = 'CC';
            scope.payByCheck = {};
            scope.payByCheckSended = false;
            scope.shippingRates = [];
            scope.accountDelivery = '';
            scope.deliveryOptionsLoading = true;

            scope.$watch('shippingRates', (newValue) => {
                if (scope.shippingRates.length == 1) {
                    scope.accountDelivery = scope.shippingRates[0].RateId;
                }
            });

            scope.$watch('accountDelivery', (newValue) => {
                if (scope.shippingRates && scope.shippingRates.length > 0) {
                    scope.selectedshippingrate = scope.shippingRates.slice(0).filter((value) => value.RateId == newValue).shift();
                }
            }, true);

            scope.$watch('billing.payBy', (newValue) => {
                scope.payby = newValue;
                if (scope.billing.payBy == 'BA') {
                    scope.data.billingAddressInfo.country = 'US';
                }
            }, true);

            scope.$watch('isBillingAddressDifferent', (newValue) => {
                if (newValue && !scope.data.billingAddressInfo.firstName && !scope.data.billingAddressInfo.lastName) {
                    scope.data.billingAddressInfo.firstName = scope.data.accountInfo.firstName;
                    scope.data.billingAddressInfo.lastName = scope.data.accountInfo.lastName;
                }
            });

            scope.$watch('data.addressID', () => {
                if (scope.data.addressID != '') {
                    scope.shippingRates = [];
                    scope.deliveryOptionsLoading = true;
                    ShippingRatesService.getShippingOptions(scope.data.addressID, scope.data.pricingParams.devices).then(
                        (response) => {
                            if (response.data.Success && response.data.Result instanceof Array) {
                                scope.shippingRates = response.data.Result;
                            }
                            scope.deliveryOptionsLoading = false;
                        },
                        (reject) => {
                            ErrorService.sendError(scope.data.accountInfo.email, JSON.stringify(reject));
                        }
                    );
                }
            });

            scope.hasCCError = (fieldName) => {
                if (!scope.ccError || !scope.ccError.fields || !scope.ccError.fields.length) {
                    return false;
                }

                return scope.ccError.fields.some(name => name === fieldName);
            };

            scope.hasBAError = (fieldName) => {
                if (!scope.baError || !scope.baError.fields || !scope.baError.fields.length) {
                    return false;
                }

                return scope.baError.fields.some(name => name === fieldName);
            };

            scope.hasParamError = (fieldName) => {
                if (!scope.paramError || !scope.paramError.fields || !scope.paramError.fields.length) {
                    return false;
                }

                return scope.paramError.fields.some(name => name === fieldName);
            };

            scope.isBillingAddressFormSwitcherVisible = () => {
                return scope.data.pricingParams.devices > 0 &&
                    (scope.billing.payBy === 'CC' 
                    || (scope.billing.payBy === 'BA' && scope.data.shippingAddressInfo.country === 'US'));
            };

            scope.isBillingAddressFormVisible = () => {
                return scope.isBillingAddressDifferent || scope.data.pricingParams.devices === 0
                    || (scope.billing.payBy === 'BA' && scope.data.shippingAddressInfo.country !== 'US');
            };

            scope.payByCheckSubmit = (form) => {
                PayByCheckService
                    .sendPayByCheckForm(scope.payByCheck.name, scope.payByCheck.email, scope.payByCheck.phone, 
                        scope.data.plan, scope.data.displays, scope.data.devices)
                    .then(
                        (response) => {
                            if (response.data.Success) {
                                scope.payByCheckSended = true;
                            }
                        },
                        (reject) => {
                            ErrorService.sendError(scope.data.accountInfo.email, JSON.stringify(reject));
                        });
            };

            scope.fillMockDataBA = () => {
                scope.data.billingInfo.routingNumber = "123456780";
                scope.data.billingInfo.accountNumber = "111111111";
                scope.data.billingInfo.accountNumberConfirmation = "111111111";
                scope.data.billingInfo.nameOnAccount = 'Leo Saturday';
                scope.data.billingAddressInfo.address = '1 Shell Drive';
                scope.data.billingAddressInfo.city = 'Los Angeles';
                scope.data.billingAddressInfo.postalcode = '90210';
                scope.data.billingAddressInfo.country = 'US';

                setTimeout(function () {
                    scope.data.billingAddressInfo.state = 'CA';
                }, 500);
            };

            scope.fillMockDataCC = () => {
                scope.data.billingInfo.number = '4111111111111111';
                scope.data.billingInfo.month = '12';
                scope.data.billingInfo.year = '2018';
                scope.data.billingAddressInfo.firstName = scope.data.billingInfo.firstName = 'Leo';
                scope.data.billingAddressInfo.lastName = scope.data.billingInfo.lastName = 'Saturday';
                scope.data.billingInfo.cvv = 123;
                scope.data.billingAddressInfo.address = '1 Shell Drive';
                scope.data.billingAddressInfo.city = 'Los Angeles';
                scope.data.billingAddressInfo.postalcode = '90210';
                scope.data.billingAddressInfo.country = 'US';

                setTimeout(function () {
                    scope.data.billingAddressInfo.state = 'CA';
                }, 500);
            };
        }
    };
};

app.directive('billingDirective', billingDirective);
