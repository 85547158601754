
import app from '../../App';

export default class RouteHelper {
    constructor ($route, $location, $window) {

        'ngInject';
        this._$route=$route;
        this._$location=$location;
        this._$window = $window;
    }

    updateRouteParams(params){
        this._$route.updateParams(params);
    }

    updateLocation(param){
        this._$location.search(param);
    }

    redirectToSlug(slug) {
      this._$window.location.href = 'https://' + this._$window.location.hostname + '/' + slug;
    }
};

app.service( 'RouteHelper', RouteHelper );