
import app from '../App';

export default class CheckoutController {

    constructor ( $scope, $location, $route,$routeParams, TaxRatesService, Info, ErrorService) {


        'ngInject';
        this.Info = Info;

        $scope.data={
            plancode:$routeParams.plancode?($routeParams.plancode==''?null:$routeParams.plancode):null,
            afmc:$routeParams.afmc?($routeParams.afmc==''?null:$routeParams.afmc):null,
            step:$routeParams.step?parseInt($routeParams.step):2,
            promocodes: ($routeParams.promocodes!=undefined&&$routeParams.promocodes!=true)?$routeParams.promocodes.split(","):[],
            validatedPromocodes:[],
            token:'',
            addressID:'',
            pricingParams: {
                displays : $routeParams.displays ? parseInt($routeParams.displays) : 1,
                devices : $routeParams.devices ? parseInt($routeParams.devices) : 1,
                plan : $routeParams.plan ? $routeParams.plan : 'annual',
                initialized: 'true',
                checkout: 'true',
            },
            accountInfo:{
                email:'',
                password:'',
                name:'',
                phone:'',
                companyName:''
            },
            shippingAddressInfo:{
                firstName: '',
                lastName: '',
                country: '',
                address: '',
                address2: '',
                city: '',
                state: '',
                postalCode: ''
            },
            billingInfo:{
                number:'',
                firstName: '',
                lastName: '',
                month: '',
                year: '',
                cvv: ''
            },
            billingAddressInfo:{
                firstName: '',
                lastName: '',
                country: '',
                address: '',
                address2: '',
                city: '',
                state: '',
                postalcode: ''
            },
            pricingInfo:{},
            deviceInfo:{},
            taxRate:{TaxRate:0}
        };
        if($routeParams.plancode){
            if(this.Info.isProduction){
                LeadDyno.recordVisit();
            }
            $scope.data.step=$routeParams.step?parseInt($routeParams.step):1;
            $scope.data.pricingParams.devices=0;
        }
        $scope.show404 = function () {
        	$location.path('/test-404');
        };
        // $location.search({step: $scope.step,displays:$scope.data.pricingParams.displays,devices:$scope.data.pricingParams.devices,plan:$scope.data.pricingParams.plan});
        // $scope.$watch('pricingParams',function(){
        //     $route.updateParams(scope.pricingParams);
        // });
        TaxRatesService.getTaxRates().then(
            (response)=>{
                if(response.data.Success){
                    $scope.data.taxRate=response.data.Result;
                }
            },
            (reject)=>{
                ErrorService.sendError("",JSON.stringify(reject));
            }
        )

    }

};

app.controller( 'CheckoutController', CheckoutController );
