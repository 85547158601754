
import template from '../../../templates/checkout-flow/checkout.directive.tpl.html';

import app from '../../App';

export function checkoutDirective(ErrorService, PromoCodeService, Info, PricingHelper, PurchaseService) {

    'ngInject';

    return {
        restrict: 'E',
        scope: {
            data: '=',
            taxrate: '='
        },
        templateUrl: template,
        link: (scope, element, attrs) => {
            scope.currentStep = parseInt(scope.data.step - 1);
            PricingHelper.getPlans(scope.data.plancode);
            scope.selectedShippingRate = { Amount: 0 };
            scope.purchaseDisabled = true;
            scope.billingPayBy = "CC";
            scope.creditCardError = '';
            scope.showCreaditCardError = false;

            scope.isPurchaseComplete = () => scope.data.step === 4;
            scope.isPurchaseInProgress = () => PurchaseService.isInProgress;

            scope.$watch('data', (newValue, oldValue) => {
                scope.currentStep = parseInt(scope.data.step - 1);

                if(scope.currentStep === 0) {
                    scope.isAnnualPlanUpsellingVisible = !scope.isAnnualPlanActive();
                }

                if (scope.currentStep == 2) {
                    scope.purchaseDisabled = false;
                    if (scope.data.pricingParams.devices > 0) {
                        if (scope.selectedShippingRate.Amount == 0) {
                            scope.purchaseDisabled = true;
                        } else {
                            scope.purchaseDisabled = false;
                        }
                    }
                } else {
                    scope.purchaseDisabled = true;
                }
            }, true);
            scope.$watch('data.shippingAddressInfo', (newValue, oldValue) => {
                scope.selectedShippingRate = { Amount: 0 };
            }, true);
            scope.$watch('selectedShippingRate', (newValue, oldValue) => {
                if ((scope.data.pricingParams.devices > 0 && scope.selectedShippingRate.Amount == 0)) {
                    scope.purchaseDisabled = true;
                } else {
                    scope.purchaseDisabled = false;
                }
            }, true);

            scope.clearErrors = () => {
                scope.baError = undefined;
                scope.ccError = undefined;
                scope.paramError = undefined;
            };

            scope.onPurchase = () => {
                scope.clearErrors();

                PurchaseService
                    .getToken(scope.billingPayBy, scope.data)
                    .then(
                        token => {
                            scope.clearErrors();
                            scope.signUpPaidService(scope.billingPayBy);
                        },
                        err => {
                            if (err.code === 'validation') {
                                if (scope.billingPayBy === 'BA') {
                                    err.message = 'Invalid Bank Account Information.';
                                    scope.baError = err;
                                } else {
                                    err.message = 'Invalid Credit Card Information.';
                                    scope.ccError = err;
                                }
                            } else if (err.code === 'invalid-parameter') {
                                scope.paramError = err;
                            }

                            ErrorService.sendError(scope.data.accountInfo.email, JSON.stringify(err));
                        });
            };

            scope.signUpPaidService = (payBy) => {
                scope.clearErrors();

                PurchaseService
                    .signUpPaidService(scope.data, scope.selectedShippingRate)
                    .then(
                        () => {
                            scope.data.step = 4;
                        },
                        errorMessage => {
                            if (payBy === 'BA') {
                                scope.baError = {message: errorMessage};
                            } else {
                                scope.ccError = {message: errorMessage};
                            }
                        });
            };

            scope.isAnnualPlanActive = () => {
                return scope.data.pricingParams.plan === 'annual';
            };

            scope.isAnnualPlanUpsellingVisible = !scope.isAnnualPlanActive();
        }
    };
};

app.directive('checkoutDirective', checkoutDirective);
