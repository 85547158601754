import app from '../../App';

export default class SignUpPaidResellerService {
    constructor($log, $http, Info,PricingHelper) {

        'ngInject';
        this._$http = $http;
        this.Info = Info;
        this.PricingHelper=PricingHelper;
    }

    signup(plancode,accountInfo,billingAddressInfo,pricingInfo,token,subscriptionDiscount,promoCodes) {
        if(this.Info.isProduction) {
            LeadDyno.recordLead(accountInfo.email);
        }
            return this._$http({
                method: 'POST',
                data: {
                    Email: accountInfo.email,
                    Company: accountInfo.companyName,
                    FirstName: accountInfo.firstName,
                    LastName: accountInfo.lastName,
                    Password: accountInfo.password,
                    NumberOfLicenses: pricingInfo.displays,
                    IsAnnual: (pricingInfo.plan == 'annual') ? true : false,
                    PlanCode: plancode,
                    BillingToken: token,
                    PromoCodeIds: promoCodes,
                    Charges: {
                        SubscriptionBase: Math.round(this.PricingHelper.getTotalLicense(pricingInfo.plan, pricingInfo.displays) * 100) / 100,
                        SubscriptionDiscount: Math.round(subscriptionDiscount * 100) / 100
                    }
                },
                url: `${this.Info.host}v1/autobilling/signup/forplan`
            });
    }
};

app.service('SignUpPaidResellerService', SignUpPaidResellerService);