import app from '../../App';
import templateUrl from './loader-button.component.tpl.html';

export class LoaderButtonComponent {
    constructor() {
        this.bindings = {
            isDisabled: '<',
            isError: '<',
            isLoading: '<',
            title: '@',
            onClick: '&'
        };
        this.templateUrl = templateUrl;
    }
}

app.component('loaderButton', new LoaderButtonComponent());
