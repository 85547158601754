import app from '../../App';

export default class CurrencyHelper {
    constructor() {
        this.formatMoney = (n, c, d, t) => {
            var c = isNaN(c = Math.abs(c)) ? 2 : c,
                d = d == undefined ? "." : d,
                t = t == undefined ? "," : t,
                s = n < 0 ? "-" : "",
                i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
                j = (j = i.length) > 3 ? j % 3 : 0;
            return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
        };
        'ngInject';
    }
    
    renderToCurrency(value, precision = 2) {
        return this.formatMoney(value, precision, '.', ',');
    };

    /**
     * Renders value without decimal part if zero
     */
    renderToCurrencySmart(value) {
        const precision = value % 1 === 0 ? 0 : 2;

        return this.renderToCurrency(value, precision);
    }

};

app.service('CurrencyHelper', CurrencyHelper);