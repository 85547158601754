import { AppMarket } from '../../App';
import template from '../../../templates/app-market/single-app.tpl.html';

AppMarket.directive('enplugSingleApp', function() {
  return {
    templateUrl: template,
    replace: true,
    scope: {
      app: '=ngModel'
    },
  };
});
