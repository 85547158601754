
import app from '../App';

export default class PricingController {

    constructor($scope, $location, $route, $routeParams, Info, PlansService, PricingHelper) {

        'ngInject';
        const plancode = $routeParams.plancode || null;
        $scope.message = Info.welcomeMessage;
        $scope.pricingParams = {};
        $scope.pricingParams.plan = $routeParams.plan ? $routeParams.plan : 'annual';
        $scope.pricingParams.displays = $routeParams.displays ? parseInt($routeParams.displays) : 5;
        $scope.pricingParams.devices = $routeParams.devices ? parseInt($routeParams.devices) : 5;
        $scope.pricingParams.initialized = $routeParams.initialized ? $routeParams.initialized : 'false';
        $scope.pricingParams.checkout = 'false';
        $scope.pricingParams.enterprise = $routeParams.initialized ? $routeParams.enterprise : 'false';;
        $route.updateParams($scope.pricingParams);
        $scope.show404 = function () {
            $location.path('/test-404');
        };
        PricingHelper.getPlans(plancode);
    }

};

app.controller('PricingController', PricingController);
