import { AppMarket } from '../../App';
import template from '../../../templates/app-market/app-categories.tpl.html';

AppMarket.directive('enplugAppCategories', function() {
  return {
    templateUrl: template,
    replace: true,
    scope: {
      categories: '=ngModel'
    },
  };
});
